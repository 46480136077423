import React from 'react';
import * as Styles from './Navigation.styles';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

// Images
import logoImage from './../../images/Jukon_Logo_White.svg';
import facebookLogo from './../../images/Facebook_icon.svg';

const Links = [
  {
    link: '#garnitury',
    name: 'Garnitury',
  },
  {
    link: '#jeansy',
    name: 'Jeansy',
  },
  {
    link: '#portfele',
    name: 'Portfele',
  },
];

gsap.registerPlugin(ScrollToPlugin);

const Navigation = () => {
  const handleClick = (e, link) => {
    e.preventDefault();
    gsap.to(window, { duration: 1.5, scrollTo: { y: link, offsetY: 80 } });
  };

  return (
    <Styles.NavContainer>
      <Styles.Nav>
        <Styles.MenuLink to='/'>
          <Styles.Logo src={logoImage} alt='Jukon Logo' />
        </Styles.MenuLink>
        <Styles.MenuWrapper>
          <Styles.Menu>
            {Links.map(({ link, name }, id) => {
              return (
                <Styles.MenuItem key={id}>
                  <Styles.MenuLink
                    to={link}
                    onClick={(e) => handleClick(e, link)}>
                    {name}
                  </Styles.MenuLink>
                </Styles.MenuItem>
              );
            })}
          </Styles.Menu>
          <a href='https://www.facebook.com/jukonemka'>
            <Styles.FbIcon src={facebookLogo} alt='Facebook Logo' />
          </a>
        </Styles.MenuWrapper>
      </Styles.Nav>
    </Styles.NavContainer>
  );
};

export default Navigation;
