export const theme = {
  fonts: {
    primary: 'Poppins, sans-serif',
    secondary: 'Playfair Display, serif',
    light: '300',
    normal: '400',
    semiBold: '700',
    bold: '800',
    black: '900',
  },
  colors: {
    primary: '#000000',
    secondary: '#ff0000',
    light: '#e7e7e7',
    dark: '#392828',
    white: '#ffffff',
  },
  mediaQueries: {
    desktop: '1200px',
    tablet: '768px',
    mobile: '375px',
  },
  media: {
    xs: '375px',
    sm: '600px',
    md: '960px',
    lg: '1200px',
    xl: '1600px',
  },
  headers: {
    h1: '32px',
    h2: '28px',
    h3: '24px',
    h4: '20px',
    h5: '16px',
    h6: '14px',
  },
  spacing: {
    xs: '4px',
    sm: '8px',
    lg: '16px',
    xl: '32px',
  },
};
