import React from 'react';
import * as Styles from './Footer.styles';

const footerData = [
  {
    text: 'Sklep Jukon, Galeria Emka',
  },
  {
    text: '75-452 Koszalin, ul. Jana Pawła II 20 ',
  },
  {
    text: 'tel. 605 321 312',
  },
];

const Footer = () => {
  return (
    <Styles.Footer>
      <Styles.FooterContent>
        {footerData.map(({ text }, id) => {
          return <Styles.Address key={id}>{text}</Styles.Address>;
        })}
      </Styles.FooterContent>
    </Styles.Footer>
  );
};

export default Footer;
