import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  } 

  body {
    margin: 0;
    padding: 0;
    font-family: ${({ theme }) => theme.fonts.secondary};
  }

  a {
    text-decoration: none;
    line-height: 0;
  }
  
`;

export default GlobalStyle;
