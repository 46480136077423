import styled from 'styled-components';
import { Link } from 'gatsby';

export const NavContainer = styled.div`
  width: 100%;
  height: 80px;
  background-color: ${({ theme }) => theme.colors.primary};
  position: fixed;
  top: 0;
  z-index: 9999;
`;

export const Nav = styled.nav`
  width: ${({ theme }) => theme.mediaQueries.desktop};
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing.lg};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.mediaQueries.desktop}) {
    width: 100%;
  }
`;

export const Logo = styled.img`
  height: 50px;
`;

export const FbIcon = styled.img`
  height: 40px;
`;

export const MenuWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const Menu = styled.ul`
  display: flex;
  align-items: center;
  color: white;
  list-style-type: none;
  gap: 16px;
  @media (max-width: 570px) {
    position: absolute;
    color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.white};
    width: 100%;
    top: 80px;
    padding: 8px;
    left: 0;
    justify-content: center;
    z-index: 999;
  }
`;
export const MenuItem = styled.li``;

export const MenuLink = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fonts.black};
  color: inherit;
  text-transform: uppercase;
`;
