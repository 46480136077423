import React from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './../styles/GlobalStyles';
import { theme } from '../themes/main-theme';
import Navigation from '../components/Navigation/Navigation';
import Footer from '../components/Footer/Footer';

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Navigation />
        {children}
        <Footer />
      </>
    </ThemeProvider>
  );
};

export default Layout;
