import styled from 'styled-components';

export const Footer = styled.footer`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const FooterContent = styled.div`
  width: ${({ theme }) => theme.mediaQueries.desktop};
  margin: 0 auto;
  display: flex;
  gap: 16px 0;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.xl};
  @media (max-width: ${({ theme }) => theme.mediaQueries.desktop}) {
    width: 100%;
  }
`;

export const Address = styled.address`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fonts.semiBold};
  font-size: 18px;
  line-height: 18px;
  break-after: always;
  text-align: center;
`;
